import { ThemeProvider as EmotionThemeProvider } from '@emotion/react'
import Color from 'color'
import React, { memo } from 'react'

import { MediaContextProvider } from './media'

export type Theme = typeof theme

export const theme = {
  colors: {
    variants: {
      primaryLight: '#B99754',
      neutralLight1: '#FFFFFF',
      neutralLight2: '#FAF9F8',
      neutralLight3: '#EFECE9',
      neutralLight4: '#CBC1B7',
      primaryDark: '#5D7FA0',
      neutralDark1: '#000000',
      neutralDark2: '#49546C',
      neutralDark3: '#5D5B5B',
      danger: '#ED0F36',
    },
    getHoverColor: (color: string, strength = 0.1): string => {
      const c = Color(color)
      return c.darken(strength).hex()
    },
  },
  fontFamily: {
    heading: "'Gilda Display', serif",
    subheading: "'Jost', sans-serif",
    paragraph: "'Crimson Text', serif",
  },
}

export const ThemeProvider = memo(function ThemeProvider({ children }: any) {
  return (
    <EmotionThemeProvider theme={theme}>
      <MediaContextProvider>{children}</MediaContextProvider>
    </EmotionThemeProvider>
  )
})
